

$blue: #1c88bf;

$brand-red: #e52320;
$transpa-white: rgba(255, 255, 255, 0.72);
$transpa-black: rgba(0, 0, 0, 0.72);
$transpa-red: #e52320e9;

body {
  background-color: white;
  padding: 0 !important;
  margin: 0 !important;
}

#root {
  margin: 0;
  padding: 0;
}

.main-box {
  padding: 0;
  margin: 0;
}

.main-nav {
  font-family: "Poppins";
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.main-nav__link {
  background-color: $brand-red;
  color: white;
  padding: 1.4rem;
  text-decoration: none;
  font-size: large;
  margin: .5rem .3rem;
  height: 20px;
  letter-spacing: .2rem;
}

.carrusel-box {
  display: flex;
  flex-direction: column;
  margin: 1rem;
  padding-top: 1rem;
}

.carrusel-box__line1 {
  background-color: $transpa-white;
  color: black;
  font-family: "Poppins";
  letter-spacing: .2rem;
  margin: .2rem;
  width: fit-content;
}

.carrusel-box__line1 h1{
  font-size: 1.5rem;
  padding: 0 1rem;
}


.carrusel-box__line2 {
  background-color: $transpa-black;
  color: white;
  font-family: "Poppins";
  letter-spacing: .2rem;
  margin: .2rem;
  width: fit-content;
}

.carrusel-box__line2 h1{
  font-size: 1.5rem;
  padding: 0 1rem;
}


.carrusel-box__line3 {
  background-color: $transpa-red;
  color: white;
  font-family: "Poppins";
  letter-spacing: .2rem;
  margin: .2rem;
  width: fit-content;
}

.carrusel-box__line3 h1{
  font-size: 1.5rem;
  padding: 0 1rem;
}

.head-bar {
  background-color: $brand-red;
  padding: 0;
  margin: 0;
  display: flex;
  height: 32px;
  color: white;
}

.maingallery-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content:center;
}

.maingallery-card {
  display: flex;
  justify-content:space-between;
  margin: 1rem;
  min-width: 360px;
  width: 360px;
  height: 266px;
  background-size: contain;
  border: lightgray solid 4px;
  list-style: none;
  text-decoration: none;
}

.main-box__img img{
  width: 100%;
}

.transac {
  margin: .5rem;
  padding: .3rem .6rem;
  color: white;
  width: fit-content;
  font-family: "Poppins";
  font-size: normal;
  line-height: .1rem;
  letter-spacing: .2rem;
}

.transactionA {
  background-color: orange;
}

.transactionV {
  background-color: $brand-red;
}

.maingallery-card__short {
  margin: .5rem;
  padding: .3rem .6rem;
  color: white;
  width: fit-content;
  font-family: "Poppins";
  font-size:large;
  background-color: rgba(0, 0, 0, 0.8);
}

.maingallery-card__short2 {
  font-size: small;
  background-color: rgba(0, 0, 0, 0.7);
}

.maingallery-card__price {
  color: black;
  font-weight: bold;
  font-size: normal;
  background-color: rgba(255, 255, 255, 0.7);
}

.property-body {

}

.property-gallery {
  margin: 1rem;
  display: flex;
}

.foot-body {
  background-color: $brand-red;
  padding: 0;
  margin: 0;
  display: flex;
  color: white;
  width: 100%;
}

.foot-nav {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 1rem auto;
  justify-content: center;
}

.foot-nav__link {
  padding: 1rem;
}

.foot-nav__link-content {
}

@media (max-width: 840px) { 
  .main-nav {
    flex-direction: column;
  }
}
